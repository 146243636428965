/**
 * Write-only the password as cookie
 */
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import React, { useState } from 'react';

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <form onSubmit={onSubmit} style={{ width: '320px' }}>
      <input
        name="password"
        type="password"
        value={password}
        onChange={event => setPassword(event.target.value)}
      />

      <br/>

      <button
        type="submit"
        onMouseEnter={() => buttonHover(true)}
        onMouseLeave={() => buttonHover(false)}
      >
        Enter
      </button>
    </form>
  );
};

export default PasswordProtect;
